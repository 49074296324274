import React from 'react';
import ipad from '../assets/images/ipad.png';
import Header from '../components/huntHeader';
import Layout from '../components/Layout';




const Clue5 = () => (
  <Layout>
    <Header /> 
 
    <header className="masthead4">
    <br></br>
    <br></br>
      <div className="container d-flex h-100 align-items-center">
        <div className="text-white-50 mx-auto text-center">
          <h1 className="mx-auto my-0 text-uppercase">Clue #5</h1>
          <br></br>
          <h2 className="tracking-in-expand mx-auto mt-2 mb-5">
            {/* {config.subHeading} */}
            From blues to jazz
            <br></br>
            and rock-n-roll.
            <br></br>
            <br></br>
            Take a blanket to sit
            <br></br>
            on a grassy knoll.
            <br></br>
            <br></br>
            Go to movies, Art Walk
            <br></br>
            and pop-ups galore;
            <br></br>
            <br></br>
            Holiday festivals
            <br></br>
            and so much more.
            <br></br>
            <br></br>
            You will definitely know
            <br></br>
            in which place to go.
            <br></br>
            <br></br>
            Just remember where
            <br></br>
            the lifeguard chairs glow.
          </h2>
            <a href="/hint5" className="btn btn-primary">
              Hint
            </a>    
            <br></br>
            <br></br>
            <br></br>       
        </div>
      </div>
    </header>

    {/* <Subscribe /> */}

    {/* <SocialLinks />
    <Footer /> */}
  </Layout>
);

export default Clue5;
